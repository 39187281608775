import * as React from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container } from "react-bootstrap"

const Layout = ({ children }) => {
  return (
    <Container fluid={true} className="p-0">
      <main className="screen-fixes">{children}</main>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
